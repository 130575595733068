import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "id": "1",
    "type": "books",
    "attributes": {
      "title": "The Great Gatsby",
      "author": "F. Scott Fitzgerald",
      "published": "1925",
      "publisher": "Scribner",
      "pages": 218
    },
    "relationships": {
      "reviews": {
        "links": {
          "self": "/books/1/reviews",
          "related": "/books/1/reviews"
        }
      }
    },
    "links": {
      "self": "/books/1"
    }
  },
  "errors": [
    {
      "status": "418",
      "title": "I'm a teapot",
      "detail": "Any attempt to brew coffee with a teapot should result in this error."
    }
  ],
  "included": [
    {
      "id": "1",
      "type": "reviews",
      "attributes": {
        "rating": 5,
        "comment": "This book is a classic."
      },
      "relationships": {
        "book": {
          "data": { "id": "1", "type": "books" }
        }
      },
      "links": {
        "self": "/reviews/1"
      }
    },
    {
      "id": "2",
      "type": "reviews",
      "attributes": {
        "rating": 3,
        "comment": "It's okay, but not my favorite."
      },
      "relationships": {
        "book": {
          "data": { "id": "1", "type": "books" }
        }
      },
      "links": {
        "self": "/reviews/2"
      }
    }
  ]
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      